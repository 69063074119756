.pop-brc {
  font-family: "Londrina Solid", sans-serif;
  overflow: hidden;
  background-color: #301228;
}

.pop-brc .navbar-custom .custom-toggler {
  border: none;
  color: #5c6895;
}

.pop-brc .navbar-custom .custom-toggler:focus {
  box-shadow: none;
}

.pop-brc .navbar-custom .custom-toggler .navbar-toggler-icon {
  background-image: url("./assets/images/hamburger.svg");
  width: 1rem;
  height: 1rem;
}

.pop-brc .navbar-custom .navbar-toggler {
  height: 48px;
}

.announce {
  padding: 18px 0px;
  top: 80px;
  width: 100%;
  & p {
    margin: 0 16px;
    color: #77fbb4;
    font-size: 24px;
    z-index: 1;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: transparent linear-gradient(270deg, #e5b06a 0%, #c56fc9 100%) 0%
      0% no-repeat padding-box;
    opacity: 0.2;
    z-index: 0;
  }
}

.navbar-toggler > .close {
  display: inline;
}
.navbar-toggler.collapsed > .close,
.navbar-toggler:not(.collapsed) > .navbar-toggler-icon {
  display: none;
}

.text-large {
  font-size: 56px;
}

.text-light-custom {
  font-weight: 300;
}

.pop-brc .navbar-custom .custom-toggler:focus {
  box-shadow: none;
}

.pop-brc .navbar-custom {
  -webkit-backdrop-filter: blur(50px) brightness(100%);
  backdrop-filter: blur(50px) brightness(100%);
  background-color: #07091880;
  padding-top: 0;
  height: 80px;
  width: 100%;
  /* width: 1320px; */
  left: 50%;
  transform: translate(-50%, 0);
  position: fixed;
  top: 0px;
  z-index: 9999;
  outline: none;
  font-size: 20px;
}

.pop-brc .navbar-custom .navbar-nav .nav-link {
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}
.pop-brc .navbar-custom .navbar-nav .nav-link .nav-text {
  position: relative;
  display: block;
}
.pop-brc .navbar-custom .navbar-nav .nav-link.active,
.pop-brc .navbar-custom .navbar-nav .nav-link:hover {
  color: #03ffe2;
}

.pop-brc .navbar-custom .navbar-nav .nav-link.active,
.pop-brc .navbar-custom .navbar-nav .nav-link:hover,
.pop-brc .navbar-custom .navbar-nav .nav-link {
  border: none;
}

.pop-brc .navbar-custom .navbar-nav .nav-link .nav-text::before {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 4px;
  background-color: #03ffe2;
  transition: all 0.1s;
  border-radius: 4px;
}

.pop-brc .navbar-custom .navbar-nav .nav-link:hover .nav-text::before {
  width: 80%;
}

.buy-btn {
  position: relative;
  /* background: linear-gradient(
    260deg,
    rgba(190, 0, 160, 0.4) 0%,
    rgba(0, 255, 226, 0.4) 100%
  ); */
  background: rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  border: solid 1px transparent;
  border-radius: 28px;
  padding: 10px 20px;
  color: white;
  box-sizing: border-box;
}
.buy-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  margin: -1px;
  border-radius: 28px;
  background: linear-gradient(100deg, #be00a0 0%, #00ffe2 100%);
}
.buy-btn .text {
  color: #00ffeb;
}

.first-section {
  background: url("./assets/images/web-bg-img_1.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 20%;
}
.first-section h1 {
  font-size: 72px;
  color: white;
  text-shadow: 6px 6px #de008999;
}
.text-yellow {
  color: #ffcf2e;
}

.play-button {
  margin-top: 56px;
  position: relative;
  width: 35%;
}
.play-button a {
  position: absolute;
  font-size: 32px;
  color: #7e3b23;
  text-transform: uppercase;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.address {
  background: #5f384d;
  border-radius: 8px;
  padding: 16px 40px;
  font-weight: 300;
  color: white;
  width: 50%;
  &.add-2 {
    .copy-position {
      top: 15px;
    }
  }
}

.text-green {
  color: #b2ffd0;
}
.pattern-section {
  padding-bottom: 136px;
  position: relative;
}
.pattern-section::before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: url("./assets/images/web-pt-1.png");
  background-position: center;
  background-repeat: repeat-x;
  opacity: 0.2;
}

.second-section {
  padding-top: 120px;
  background-color: #301228;
  padding-bottom: 160px;
  color: white;
  font-weight: 300;
}

.second-section p {
  font-size: 20px;
}

.text-green-title {
  color: #03ffe2;
}
.text-underline {
  font-size: 56px;
  text-transform: uppercase;
  margin-bottom: 40px;
}
.text-underline::before {
  bottom: -8px;
  content: "";
  position: absolute;
  height: 4px;
  border-radius: 4px;
  width: 160px;
}
.text-green-title::before {
  background: #03ffe2;
}

.second-section .upper {
  margin-bottom: 120px;
}
.second-section .content {
  z-index: 1;
}

.pbrc-border {
  border-radius: 8px;
  background-color: rgba(95, 56, 77, 0.2);
  padding: 14px 40px;
}
.pbrc-border p {
  margin-bottom: 0;
}

.character-card {
  border-radius: 24px;
  box-shadow: inset 0px 0px 0px 1px #ffffff33;
  padding: 24px 0;
}
/* .character-card.animation {
  padding: 0;
} */
.character-card.animation img {
  animation: move 3s infinite linear;
}

.doge {
  background: transparent linear-gradient(142deg, #ffe086 0%, #742167 100%) 0%
    0% no-repeat padding-box;
}
.pepe {
  background: transparent linear-gradient(142deg, #ffe086 0%, #217457 100%) 0%
    0% no-repeat padding-box;
}
.shiba {
  background: transparent linear-gradient(142deg, #ffe086 0%, #742121 100%) 0%
    0% no-repeat padding-box;
}

.gameplay-col {
  padding-top: 80px;
}

.gameplay {
  font-size: 20px;
  color: #03ffe2;
  font-weight: 300;
  margin-bottom: 40px;
}

.buy-pbrc {
  color: #4d3411;
  text-shadow: 0px 2px 0px #ffeeb9;
  background: #d6ba7e 0% 0% no-repeat padding-box;
  box-shadow: inset 4px 8px 0px #edd99c, 0px 12px 0px #00000033;
  border: 2px solid #000000;
  border-radius: 24px;
  text-decoration: none;
  padding: 15px 24px;
}
.buy-pbrc p {
  font-size: 24px;
  font-weight: 400;
}

.overlay-1 {
  top: -9%;
  left: -20%;
  z-index: -1;
}

.overlay-2 {
  top: 28%;
  right: -18%;
  z-index: -1;
}

.overlay-3 {
  z-index: -1;
  bottom: -9%;
}

.third-section {
  background-image: url("./assets/images/web-bg-img_2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  padding-bottom: 160px;
}

/* .proof-section {
  padding-top: 120px;
} */
.proof-section::before {
  top: 0;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("./assets/images/web-pt-2.png");
  background-position: top;
  background-repeat: repeat-x;
  opacity: 0.2;
}
.text-yellow-title {
  color: #ffd880;
}
.text-yellow-title::before {
  background: #ffd880;
}

.text-yellow-proof {
  color: #ffd880;
  font-size: 20px;
}

.comparison {
  background: rgba(0, 0, 0, 0.1) 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 0px 0px 1px #ffffff33;
  border-radius: 24px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  padding: 16px 8px;
  margin-top: 120px;
}

.coin-col {
  background: #ffffff1a 0% 0% no-repeat padding-box;
  border: 1px solid #e2d1b2;
  border-radius: 24px;
  padding-top: 136px;
}

.content-card {
  padding: 24px 16px;
  background: rgba(0, 0, 0, 0.2) 0% 0% no-repeat padding-box;
}

.coin-title {
  font-size: 20px;
  font-weight: 400;
}

.coin-content {
  font-size: 20px;
  color: white;
  font-weight: 300;
}

.coin-col .content-card:last-child {
  border-radius: 0 0 24px 24px;
}

.coin {
  top: -20%;
  left: 50%;
  transform: translate(-50%, 20%);
  width: 200px;
}

.fourth-section,
.fifth-section,
.sixth-section,
.eighth-section,
.eleventh-section {
  padding-top: 120px;
  padding-bottom: 160px;
  background-color: #452331;
  position: relative;
}
.twelfth-section {
  background-color: #452331;
  position: relative;
}
.fourth-section::before,
.eighth-section::before,
.twelfth-section::before {
  content: "";
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url("./assets/images/web-pt-1.png");
  background-position: bottom;
  background-repeat: repeat-x;
  opacity: 0.2;
}
.fourth-section::after,
.eighth-section::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: transparent
    linear-gradient(90deg, #ffffff00 0%, #ffffff 49%, #ffffff00 100%) 0% 0%
    no-repeat padding-box;
  opacity: 0.4;
  bottom: 0;
}

.text-underline-2 {
  font-size: 56px;
  position: relative;
}
.text-underline-2::before {
  content: "";
  position: absolute;
  bottom: -8px;
  width: 160px;
  height: 4px;
  left: 50%;
  transform: translateX(-50%);
}
.text-pink {
  color: #ff90b6;
}
.text-pink.text-underline-2::before {
  background-color: #ff91b6;
  border-radius: 4px;
}

.mechanics {
  margin-top: 120px;
}

.mechanics-pill {
  background: transparent
    linear-gradient(247deg, #776cb0 0%, #785299 63%, #a25878 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: inset 0px 0px 0px 1px #ffffff33;
  border-radius: 24px;
  padding: 32px 16px 24px 28px;
}

.coin-pill {
  background: transparent
    linear-gradient(247deg, #f69800 0%, #bf603d 63%, #9b4242 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: inset 0px 0px 0px 1px #ffffff33;
  border-radius: 24px;
  padding: 32px 16px 24px 28px;
}

.text-red {
  color: #fd3c3c;
  font-size: 20px;
  position: relative;
}
.red-arrow::before {
  content: "";
  position: absolute;
  bottom: -8px;
  width: 98%;
  height: 2px;
  background-color: #fd3c3c;
  border-radius: 1px;
}
.red-arrow::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fd3c3c;
  position: absolute;
  bottom: -17px;
  right: 0;
  border-radius: 2px;
}

.yellow-arrow::before {
  content: "";
  position: absolute;
  bottom: -8px;
  width: 98%;
  height: 2px;
  background-color: #ffcf2e;
  border-radius: 1px;
}
.yellow-arrow::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 15px solid #ffcf2e;
  position: absolute;
  bottom: -17px;
  left: 0;
  border-radius: 2px;
}
.mechanics .text-yellow {
  font-size: 20px;
}

.bridge-img {
  box-shadow: 0px 8px 40px #be00a8;
  border-radius: 24px;
}

.arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -2px;
  width: 40px;
  height: 2px;
  background-color: #fd3c3c;
  border-radius: 1px;
}
.arrow::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fd3c3c;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -2px;
  border-radius: 2px;
}
.pseudo {
  height: 100%;
  width: 18%;
  position: absolute;
  top: 0;
  left: -5%;
}

.custom-col {
  background: #00000033 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 0px 0px 1px #ffffff33;
  border-radius: 24px;
}

.mecha-compare {
  width: 100%;
  height: 100%;
  background-color: #60384d;
  border: 1px solid #e2b2c8;
  border-radius: 24px;
}

.title-row {
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  padding: 40px 16px 24px;
}

.compare-title {
  margin-bottom: 0;
  color: #b2ffd0;
  font-size: 32px;
}

.content-row {
  background: rgba(0, 0, 0, 0.1) 0% 0% no-repeat padding-box;
  padding: 40px 16px 0;
}
.content-row p {
  font-size: 20px;
  color: white;
  font-weight: 300;
}

.fifth-section {
  position: relative;
}

.fifth-section::before,
.sixth-section::before,
.ninth-section::before,
.tenth-section::before {
  content: "";
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url("./assets/images/web-pt-1.png");
  background-position: bottom;
  background-repeat: repeat-x;
  opacity: 0.2;
  transform: rotate(180deg);
}
.fifth-section::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: transparent
    linear-gradient(90deg, #ffffff00 0%, #ffffff 49%, #ffffff00 100%) 0% 0%
    no-repeat padding-box;
  opacity: 0.4;
  bottom: 0;
}

.fifth-pseudo,
.ninth-pseudo {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("./assets/images/web-pt-1.png");
  background-position: bottom;
  background-repeat: repeat-x;
  opacity: 0.2;
}

.fifth-row {
  margin-top: 136px !important;
}

.proof-card {
  background: #6c2f48 0% 0% no-repeat padding-box;
  box-shadow: inset 4px 4px 0px #9a4a6a, 0px 24px 0px #00000033;
  border: 4px solid #000000;
  border-radius: 24px;
  padding-top: 126%;
  position: relative;
}
.proof-card-img {
  left: 50%;
  transform: translateX(-50%);
  top: -12%;
  width: 96%;
  display: block;
}

.proof-content {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 13%;
  left: 50%;
  transform: translateX(-50%);
}

.proof-content p {
  font-size: 20px;
  font-weight: 300;
  color: white;
}

.proof-content .title {
  font-size: 32px;
  color: #b2ffd0;
  font-weight: 400;
}

.cex {
  color: #fbff9a;
  font-size: 20px;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.text-inhouse {
  font-weight: 300;
  font-size: 20px;
  color: white;
  width: 75%;
  margin: 40px auto 120px;
}

.inhouse {
  width: 60%;
}

.inhouse-content {
  position: absolute;
  text-align: center;
  bottom: 16px;
  width: 100%;
}

.inhouse-content p {
  font-weight: 300;
  color: white;
  font-size: 20px;
}

.inhouse-content p:first-child {
  font-weight: 400;
  color: #b2ffd0;
  font-size: 32px;
}

.integration {
  color: #fbff9a;
  font-size: 32px;
  position: absolute;
  bottom: 60px;
  width: 100%;
  text-align: center;
  letter-spacing: 0px;
  line-height: 40px;
}

.inhouse-img {
  box-shadow: 0px 8px 80px #be00a8;
  border-radius: 24px;
}

.sixth-section::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: transparent
    linear-gradient(90deg, #ffffff00 0%, #ffffff 49%, #ffffff00 100%) 0% 0%
    no-repeat padding-box;
  opacity: 0.4;
  bottom: 0;
}

.seventh-section {
  padding: 120px 0 160px;
  background-image: url("./assets/images/web-bg-img_3.png");
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  background-color: #452331;
}

.text-orange {
  color: #fda43c;
}
.text-underline-3 {
  font-size: 56px;
  position: relative;
}
.text-underline-3::before {
  content: "";
  position: absolute;
  bottom: -8px;
  width: 160px;
  height: 4px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fda43c;
  border-radius: 4px;
}

.hub-text {
  font-size: 20px;
  font-weight: 300;
  margin-top: 0px;
  margin-bottom: 0px;
}

.dot-text p {
  font-size: 20px;
  color: white;
  font-weight: 300;
}

.video-section {
  margin-top: 120px;
}

.video {
  margin-top: 56px;
  width: 68% !important;
}
.video iframe {
  border-radius: 16px;
  border: 4px solid #000000;
}

.tokenomics {
  margin-top: 120px;
}

.chart-text {
  bottom: 35%;
  left: 50%;
  transform: translateX(-50%);
}

.chart-text p {
  color: white;
  font-size: 20px;
  font-weight: 300;
}

.chart-text .text-orange {
  font-size: 80px;
  color: #fda43c;
  font-weight: 400;
}

.tokenomic-items-wrapper {
  display: grid;
  grid-template-areas: "short1 short2 short3 long";
  grid-template-columns: 190px 50px 69px 1fr;
  grid-template-rows: 1fr;
  place-items: center start;
  font-size: 20px;
  color: white;
  background: #5f384d 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 12px 0;
  margin-bottom: 4px;
  position: relative;
  font-weight: 300;
}
.tokenomic-items-wrapper p:first-child {
  padding-left: 40px;
  font-weight: 400;
}
.dot {
  position: absolute;
  left: -8px;
  width: 1rem;
}
.dot-pop {
  filter: invert(95%) sepia(9%) saturate(1532%) hue-rotate(133deg)
    brightness(85%) contrast(89%);
}
.dot-liquid {
  filter: invert(53%) sepia(26%) saturate(1136%) hue-rotate(292deg)
    brightness(97%) contrast(82%);
}
.dot-team {
  filter: invert(59%) sepia(37%) saturate(375%) hue-rotate(209deg)
    brightness(86%) contrast(91%);
}
.dot-fund {
  filter: invert(72%) sepia(60%) saturate(344%) hue-rotate(29deg)
    brightness(94%) contrast(101%);
}
.dot-cex {
  filter: invert(43%) sepia(10%) saturate(2127%) hue-rotate(227deg)
    brightness(96%) contrast(88%);
}
.dot-ido {
  filter: invert(69%) sepia(25%) saturate(672%) hue-rotate(327deg)
    brightness(97%) contrast(92%);
}
.dot-marketing {
  filter: invert(67%) sepia(19%) saturate(674%) hue-rotate(239deg)
    brightness(85%) contrast(87%);
}

.roadmap-row {
  padding-top: 100px;
}

.roadmap-card {
  position: relative;
  padding-top: 126%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.roadmap-card img {
  top: -4%;
  left: 50%;
  transform: translateX(-50%);
}
.roadmap-card .title {
  top: -2%;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 32px;
}
.roadmap-card .content {
  width: 80%;
  font-size: 20px;
  font-weight: 300;
  top: 18%;
  left: 10%;
}
.content-1 {
  color: #5d1137;
}
.content-2 {
  color: #124032;
}
.content-3 {
  color: #1a415a;
}
.content-4 {
  color: #522708;
}

.card-1 {
  background-image: url("./assets/images/bg-img-phase_1.png");
}
.card-2 {
  background-image: url("./assets/images/bg-img-phase_2.png");
  margin-top: 4rem;
}
.card-3 {
  background-image: url("./assets/images/bg-img-phase_3.png");
}
.card-4 {
  background-image: url("./assets/images/bg-img-phase_4.png");
  margin-top: 4rem;
}

.sog {
  margin-top: 120px;
}

.quote-section {
  position: absolute;
  bottom: 12%;
  width: 50%;
  left: 50%;
  transform: translateX(-50%);
}

.highlight {
  background: #a2476e 0% 0% no-repeat padding-box;
  box-shadow: inset 4px 4px 0px #c46284, 0px 4px 0px #00000033;
  border: 2px solid #000000;
  border-radius: 16px;
  padding: 24px 40px;
}

.quote {
  font-size: 20px;
  color: white;
  text-align: center;
}

.highlight p {
  color: #77fbed;
  font-size: 32px;
}

.highlight p span {
  color: white;
  font-size: 20px;
}

.we {
  width: 67%;
  text-align: center;
  color: white;
}
.we h3 {
  margin-top: 24px;
  font-size: 32px;
}
.we p {
  font-size: 20px;
  font-weight: 300;
}
.ninth-section {
  padding-top: 120px;
  background-color: #452331;
  position: relative;
}

.tenth-section {
  padding-top: 77px;
  background-image: url("./assets/images/web-bg-img_4.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.footer {
  position: relative;
  height: 320px;
  background-image: url("./assets/images/web-bg-footer.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.footer-icon {
  background: transparent linear-gradient(135deg, #d96ac8 0%, #00b19c 100%) 0%
    0% no-repeat padding-box;
  box-shadow: inset 0px 0px 0px 1px #ffffff33;
  border-radius: 8px;
  padding: 16px;
}
.footer-text {
  color: white;
  font-size: 20px;
  margin-top: 46px;
}
.footer-content {
  top: 42%;
  left: 50%;
  transform: translateX(-50%);
}

.arrow-user::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2px;
  width: 65px;
  height: 2px;
  /* background-color: #fd3c3c; */
  border-radius: 1px;
}
.arrow-blue::before {
  background-color: #77fbed;
}
.arrow-blue::after {
  border-left: 15px solid #77fbed;
}
.arrow-yellow::before {
  background-color: #ffcf2e;
}
.arrow-yellow::after {
  border-left: 15px solid #ffcf2e;
}

.arrow-user::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1px;
  border-radius: 2px;
}
.pseudo-user {
  height: 100%;
  width: 25%;
  position: absolute;
  top: 0;
  right: -77px;
}
.pseudo-user .play {
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  font-size: 20px;
  color: #77fbed;
}

.pseudo-reward {
  height: 100%;
  width: 25%;
  position: absolute;
  top: 0;
  right: -21px;
}
.pseudo-reward .rewards {
  top: 40%;
  left: 70%;
  transform: translate(-50%, -40%);
  font-size: 20px;
  color: #ffcf2e;
}

.copy-position {
  right: 25px;
}

.coin-img {
  width: 320px;
}

.buy-pbrc {
  width: 50%;
}

.benefits {
  width: 50%;
}

.text-hub {
  width: 50%;
}

.dot-text {
  width: 75%;
}

.sog-img {
  width: 68%;
}

.pop-brc-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(50px) brightness(100%);
  backdrop-filter: blur(50px) brightness(100%);
  background-color: #07091880;
  z-index: 100;
}

.modify {
  margin-top: 2rem;
  margin-bottom: 40px;
}

.play-button span {
  display: inline-block;
}

.fx img,
.fx-self,
.buy-btn p,
.buy-pbrc p,
.play-button span {
  transition: all 0.1s;
}
.fx:hover img,
.fx-self:hover {
  transform: scale(1.2);
}
.buy-btn:hover p,
.buy-pbrc:hover p {
  transform: scale(1.1);
}
.play-button:hover span {
  transform: scale(1.1);
}

@keyframes move {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.backers {
  padding-top: 64px;
}

.backer-item {
  background: #5f384d;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  padding: 12px;
  height: 160px;
  img {
    width: 100%;
  }
}

.backer-item#banter,
.backer-item#icetea,
.backer-item#tcvn,
.backer-item#wld {
  img {
    width: 60%;
  }
}
.backer-item#zbs {
  img {
    width: 50%;
  }
}

.growth-hacking {
  margin-top: 240px;
  .growth {
    text-align: center;
    width: 60%;
    margin: 20px auto 16px;
  }
  .proof-card {
    padding-top: 151%;
  }
  .fifth-row {
    margin-top: 120px !important;
  }
  .proof-content {
    padding: 0 20px;
    bottom: 8%;
    p {
      font-size: 24px;
    }
    .title {
      font-size: 32px;
      margin-bottom: 24px;
    }
  }
  .play-button {
    margin-top: 13px;
    width: 100%;
    background: #3aea84 0% 0% no-repeat padding-box;
    box-shadow: inset 4px 8px 0px #56fc9b, 0px 12px 0px #00000033;
    border: 2px solid #000000;
    border-radius: 24px;
    width: 79%;
    padding: 26px 0 24px;
    p {
      font-size: 32px;
      font-weight: 700;
      color: #104d38;
      margin-bottom: 0;
      transition: all 0.2s;
    }
    &:hover {
      p {
        transform: scale(1.2);
      }
    }
  }
}

@media (max-width: 1200px) {
  .navbar-nav {
    font-size: 16px;
  }
  .pop-brc .navbar-custom .navbar-nav .nav-link {
    padding-left: 16px;
  }
  .fifth-row .proof-card .proof-content p {
    font-size: 16px;
  }
  .fifth-row .proof-card .proof-content .title {
    font-size: 24px;
  }
  .roadmap-card p {
    font-size: 15px;
  }
  .growth-hacking {
    & .play-button {
      padding: 12px 0 12px;
    }
    & .proof-content p {
      font-size: 19px;
    }
  }
  .integration-section {
    .inhouse-img {
      width: 347px;
    }
    .integration {
      font-size: 24px;
    }
    .inhouse-content p:first-child {
      font-size: 24px;
    }
    .inhouse-content p {
      font-size: 18px;
    }
  }
}

@media (max-width: 992px) {
  .proof-content .title {
    font-size: 32px;
    margin-bottom: 3px;
  }
  .growth-hacking .proof-card {
    padding-top: 103%;
  }
  .fifth-row .proof-card {
    padding-top: 100%;
  }
  .growth-row .fifth-col:not(:last-child),
  .fifth-row .fifth-col:not(:last-child) {
    margin-bottom: 8rem;
  }
  .fifth-row .proof-card .proof-content p {
    font-size: 20px;
  }
  .fifth-row .proof-card .proof-content .title {
    font-size: 32px;
  }
  .growth-hacking .play-button {
    padding: 18px 0 18px;
  }
  .red-arrow-mobile::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 2px;
    top: -14%;
    right: -100%;
    background: #fd3c3c;
  }
  .red-arrow-mobile::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid #fd3c3c;
    bottom: 10%;
    right: -115%;
    border-radius: 2px;
  }
  .yellow-arrow-mobile::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 2px;
    top: -10%;
    right: -100%;
    background: #ffcf2e;
  }
  .yellow-arrow-mobile::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #ffcf2e;
    top: -14%;
    right: -116%;
    border-radius: 2px;
  }
  .middle-arrow {
    position: absolute;
    width: 100%;
    height: 32px;
    bottom: 0;
  }

  .middle-arrow::before {
    content: "";
    position: absolute;
    height: 32px;
    width: 2px;
    bottom: -42px;
    left: 50%;
    transform: translateX(-50%);
    background: #fd3c3c;
  }
  .middle-arrow::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid #fd3c3c;
    position: absolute;
    bottom: -44px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
  }
  .pill-row {
    margin-top: 80px !important;
    margin-bottom: 48px;
  }
  .mechanics-pill,
  .coin-pill {
    padding: 16px 12px 8px;
    height: 100%;
  }
  .bridge-img {
    margin-bottom: 48px;
  }
  .integration-section {
    margin: 56px 0;
  }
  .integration-section .inhouse-img {
    width: 100%;
  }
  .bridge-inhouse {
    position: absolute;
    font-size: 14px;
    font-weight: 300;
    color: white;
    bottom: 1%;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
  }
  .integration {
    bottom: 35%;
  }
  .bridge-section {
    margin-top: 60px;
  }
  .pseudo-user-mobile .play,
  .pseudo-user-mobile .rewards {
    bottom: -30px;
    font-size: 14px;
    font-weight: 400;
    left: 59%;
    transform: translateX(-59%);
  }
  .pseudo-user-mobile::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    bottom: -44px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
  }
  .pseudo-user-mobile.blue::before {
    background: #77fbed;
  }
  .pseudo-user-mobile.blue::after {
    border-top: 15px solid #77fbed;
  }
  .pseudo-user-mobile::before {
    content: "";
    position: absolute;
    height: 32px;
    width: 2px;
    bottom: -42px;
    left: 50%;
    transform: translateX(-50%);
  }
  .pseudo-user-mobile.yellow::before {
    background: #ffcf2e;
  }
  .pseudo-user-mobile.yellow::after {
    border-top: 15px solid #ffcf2e;
  }
  .mobile-phase {
    margin-top: 80px;
  }
  .mobile-titlephase {
    left: 50%;
    transform: translateX(-50%);
    top: -6%;
    width: 75%;
  }
  .mobile-phase .title {
    font-size: 20px;
    top: -3%;
    left: 50%;
    transform: translateX(-50%);
    color: white;
  }
  .mobile-phase .content {
    width: 80%;
    top: 12%;
    left: 10%;
    font-size: 20px;
    font-weight: 300;
  }
  .mobile-phase .content p {
    margin-bottom: 0;
  }
  .minus-margin {
    margin-top: -6rem !important;
  }
  .mobile-phase .col-8:nth-child(even) {
    margin-left: auto;
  }
  .backer-item#banter,
  .backer-item#icetea,
  .backer-item#tcvn,
  .backer-item#wld {
    img {
      width: 48%;
    }
  }
  .backer-item#zbs {
    img {
      width: 40%;
    }
  }
}

@media (max-width: 768px) {
  .growth-hacking .play-button {
    border-radius: 16px;
  }
  .announce {
    top: 48px;
    p {
      font-size: 14px;
    }
  }
  .address {
    padding: 16px 7px;
    font-size: 14px;
  }
  .copy-position {
    right: 5px;
  }
  .backer-item#zbs {
    img {
      width: 55%;
    }
  }
  .backer-item#banter,
  .backer-item#icetea,
  .backer-item#tcvn,
  .backer-item#wld {
    img {
      width: 80%;
    }
  }
  .growth-hacking {
    margin-top: 120px;
    .growth {
      width: 90%;
    }
    .proof-card {
      padding-top: 108%;
    }
    .proof-content {
      bottom: 6%;
      width: 100%;
      p {
        font-size: 14px;
      }
      .title {
        font-size: 20px;
        margin-bottom: 3px;
      }
      .play-button {
        padding: 10px;
      }
    }
  }
  .pop-brc .navbar-custom {
    -webkit-backdrop-filter: blur(50px) brightness(100%);
    backdrop-filter: blur(50px) brightness(100%);
    /* backdrop-filter: none; */
    background-color: rgba(255, 255, 255, 0.05);
    width: 100%;
  }
  .mobile-nav .collapse.show {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    backdrop-filter: blur(50px) brightness(100%);
    background-color: rgba(255, 255, 255, 0.05);
    position: relative;
    top: 0;
    height: 75vh;
  }
  .first-section {
    padding-top: 30%;
    background: url("./assets/images/mobile-bg-img_1.png");
  }
  .first-section h1 {
    font-size: 40px;
    margin-top: 220px;
    margin-bottom: 40px;
  }
  .address {
    width: 100%;
  }
  .mobile-buy {
    margin-top: 40px;
    width: 80%;
    margin: 40px auto 0px;
    text-align: center;
    padding: 16px 20px;
  }
  .first-section {
    background-position: 50% -14%;
  }
  .second-section {
    margin-top: -1px;
    padding-top: 80px;
    padding-bottom: 120px;
  }
  .text-green-title,
  .text-yellow-title {
    font-size: 48px;
    text-align: center;
  }
  .text-green-title::before,
  .text-yellow-title::before {
    width: 80px;
    left: 50%;
    transform: translateX(-50%);
  }

  .second-section p {
    font-size: 14px;
  }
  .second-section .upper {
    margin-bottom: 80px;
  }
  .coin-img {
    width: 240px;
    margin-top: 40px;
  }
  .text-large {
    font-size: 48px;
  }
  .gameplay {
    text-align: center;
  }

  .buy-pbrc {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
  .buy-pbrc p {
    font-size: 24px;
  }

  .third-section {
    margin-top: -1px;
    background-image: url("./assets/images/mobile-bg-img_2.png");
    padding-bottom: 120px;
  }

  .text-yellow-proof {
    font-size: 14px;
    text-align: center;
  }

  .coin {
    width: 120px;
    top: -15%;
  }
  .coin-col {
    padding-top: 76px;
  }
  .coin-title,
  .coin-content {
    font-size: 14px;
  }
  .text-underline-2 {
    font-size: 48px;
  }

  .fourth-section,
  .fifth-section,
  .sixth-section,
  .eighth-section,
  .eleventh-section {
    padding-top: 80px;
    padding-bottom: 120px;
  }

  .mechanics-mobile {
    margin-top: 60px;
  }
  .mechanics-mobile .text-red {
    font-size: 14px;
    margin-top: 8px;
  }

  .mechanics-pill,
  .coin-pill {
    padding: 16px 12px 8px;
    height: 100%;
  }

  .pill-row {
    margin-bottom: 48px;
  }

  .coin-content {
    margin-bottom: 20px;
  }
  .cex {
    font-size: 14px;
    bottom: 15px;
    left: 40%;
    transform: translateX(-20%);
  }
  /* .pseudo {
    height: 18%;
    width: 100%;
  }
  .arrow::before {
    width: 2px;
    height: 50px;
  }
  .arrow::after {
    border-bottom: none;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-top: 20px solid #fd3c3c;
    top: 100%;
    right: -6px;
  } */
  .bridge-img {
    margin-bottom: 48px;
  }
  .compare-title {
    font-size: 20px;
  }
  .content-row p {
    font-size: 14px;
  }
  .benefits {
    width: 100%;
  }

  .proof-card {
    padding-top: 96%;
    border: 2px solid #000000;
  }
  .fifth-row .fifth-col:not(:last-child) {
    margin-bottom: 8rem;
  }
  .proof-card-img {
    display: block;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    top: -20%;
  }
  .fifth-row .proof-card .proof-content p {
    font-size: 14px;
  }
  .fifth-row .proof-card .proof-content .title {
    font-size: 24px;
  }
  .inhouse {
    width: 100%;
  }
  .text-underline-2::before {
    width: 80px;
  }
  .integration-section {
    margin: 56px 0;
  }
  .integration {
    font-size: 24px;
    bottom: 39%;
  }
  .bridge-inhouse {
    position: absolute;
    font-size: 14px;
    font-weight: 300;
    color: white;
    bottom: 1%;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
  }
  .inhouse-content p:first-child {
    font-size: 24px;
  }
  .inhouse-content p {
    font-size: 14px;
  }

  .seventh-section {
    padding: 80px 0 120px;
    background: url("./assets/images/mobile-bg-img_3.png");
  }

  .text-hub {
    width: 100%;
  }

  .text-underline-3 {
    font-size: 48px;
  }
  .text-underline-3::before {
    width: 80px;
  }
  .hub-text {
    font-size: 14px;
  }
  .dot-text {
    width: 100%;
  }
  .dot-text p {
    font-size: 14px;
  }
  .video-section {
    margin-top: 80px;
  }
  .video {
    width: 100% !important;
  }
  .video iframe {
    border-width: 2px;
  }

  .chart-text {
    bottom: 38%;
  }
  .chart-text p {
    font-size: 16px;
  }
  .chart-text .text-orange {
    font-size: 48px;
  }
  .dot {
    display: none;
  }
  .tokenomics {
    margin-top: 80px;
  }
  .tokenomic-items-wrapper {
    grid-template-columns: 60px 39px 95px 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "short1 short1 . long"
      "short2 short3 . long";
    font-size: 14px;
    padding-left: 24px;
  }
  .tokenomic-items-wrapper p:first-child {
    padding-left: 0;
  }
  .comparison {
    margin-top: 80px;
  }
  .backers {
    padding-top: 40px;
  }

  .ninth-section {
    padding-top: 80px;
  }
  .quote {
    font-size: 14px;
  }
  .quote-section {
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    bottom: -11%;
  }
  .highlight {
    padding: 16px;
  }
  .highlight p {
    font-size: 16px;
  }
  .highlight p span {
    font-size: 14px;
  }
  .sog-social img {
    width: 20px;
  }
  .we {
    width: 100%;
  }
  .we h3 {
    margin-top: 48px;
    margin-bottom: 16px;
    font-size: 24px;
  }
  .we p {
    font-size: 14px;
  }

  .tenth-section {
    margin-top: -1px;
    padding-top: 250px;
    background-image: url("./assets/images/mobile-bg-img_4.png");
  }
  .sog {
    margin-top: 80px;
  }
  .footer-content {
    width: 75%;
  }
  .footer-text {
    font-size: 14px;
    text-align: center;
    margin-top: 24px;
  }
  .mobile-phase {
    margin-top: 80px;
  }
  .mobile-titlephase {
    left: 50%;
    transform: translateX(-50%);
    top: -6%;
    width: 75%;
  }
  .mobile-phase .title {
    font-size: 16px;
    top: -3%;
    left: 50%;
    transform: translateX(-50%);
    color: white;
  }
  .mobile-phase .content {
    width: 80%;
    top: 12%;
    left: 10%;
    font-size: 13px;
    font-weight: 300;
  }
  .mobile-phase .content p {
    margin-bottom: 0;
  }
  .minus-margin {
    margin-top: -6rem !important;
  }
  .mobile-phase .col-8:nth-child(even) {
    margin-left: auto;
  }
  .mobile-buy {
    background: linear-gradient(
      260deg,
      rgba(190, 0, 160, 0.4) 0%,
      rgba(0, 255, 226, 0.4) 100%
    );
  }
  .inhouse-img {
    width: 100%;
  }

  .pseudo-user-mobile {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 40px;
  }

  .red-arrow-mobile::before {
    top: -14%;
    right: -100%;
  }
  .red-arrow-mobile::after {
    bottom: 8%;
    right: -135%;
  }
  .yellow-arrow-mobile::before {
    top: -10%;
    right: -100%;
  }
  .yellow-arrow-mobile::after {
    top: -15%;
    right: -137%;
  }

  .middle-arrow {
    position: absolute;
    width: 100%;
    height: 32px;
    bottom: 0;
  }

  .middle-arrow::before {
    content: "";
    position: absolute;
    height: 32px;
    width: 2px;
    bottom: -42px;
    left: 50%;
    transform: translateX(-50%);
    background: #fd3c3c;
  }
  .middle-arrow::after {
    bottom: -44px;
    left: 50%;
    transform: translateX(-50%);
  }
  .sog-img {
    width: 100%;
  }
  .container {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .buy-pbrc {
    padding: 12px 24px;
  }
  .character-card,
  .comparison,
  .coin-col,
  .mechanics-pill,
  .coin-pill,
  .custom-col,
  .mecha-compare {
    border-radius: 16px;
  }
  .highlight,
  .video iframe {
    border-radius: 8px;
  }
  .pop-brc .navbar-custom {
    height: 48px;
  }
  .mobile-nav.container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .coin-col .content-card:last-child {
    border-radius: 0 0 16px 16px;
  }
  .first-section h1 {
    text-shadow: 4px 4px #de008999;
  }
  .pop-brc .navbar-custom .navbar-nav .nav-link {
    padding-left: 64px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .pop-brc .navbar-custom .navbar-nav .nav-link:last-child {
    padding-bottom: 48px;
    width: 100%;
    padding-right: 64px;
    text-align: center;
  }
  .navbar-nav {
    height: 100%;
  }
}

@media (min-width: 1400px) {
  .container-lg,
  .container {
    max-width: 1170px !important;
  }
}
